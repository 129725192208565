import React from "react";
import PropTypes from "prop-types";

export default class Modal extends React.Component {
  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };
  render() {
    let modalClasses = "modal";
    if (this.props.show) {
        modalClasses += " is-active";
    }
    return (
      <div className={modalClasses} id="modal">
        <div className="modal-background" onClick={this.onClose}></div>
        <div className="modal-content">
            <div className="box">
                <div className="content">{this.props.children}</div>
            </div> 
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={this.onClose}></button>
      </div>
    );
  }
}
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};