import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../utils/store'
import logo from '../imgs/logo.svg';
import { Link, useHistory, useLocation } from "react-router-dom";

function Login() {
  
  const [email, setEmail] = React.useState('');
  const [barcode, setBarcode] = React.useState('');
  const [club, setClub] = React.useState('4893');
  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoginLoading, setIsLoginLoading] = React.useState(false);
  const apiUrl = useSelector(state => state.apiUrl);

  const user = useSelector(state => state.user);

  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  const handleLogin = () => {
    setIsLoginLoading(true);
    let options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        barcode: barcode,
        club: club
      }),
    }
    
    fetch(apiUrl + '/membre/', options)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.success){
            let newUser = result.member;

            dispatch(login(newUser));
            let { from } = location.state || { from: { pathname: "/admin" } };
            history.replace(from);
          }else{
            setError(result.message)
          }
          setIsLoginLoading(false);
        },
        // Remarque : il est important de traiter les erreurs ici
        // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
        // des exceptions provenant de réels bugs du composant.
        (error) => {
          setError('Une erreur est survenue, réessayez plus tard.')
          setIsLoginLoading(false);
        }
      )
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      //setIsLoading(false);
      console.log(user)
      let options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: user,
        }),
      }
      
      fetch(apiUrl + '/membre/validate.php', options)
        .then(res => res.json())
        .then(
          (result) => {
            if (result.success){
              let newUser = result.member;
  
              dispatch(login(newUser));
              let { from } = location.state || { from: { pathname: "/admin" } };
              history.replace(from);
            }else{
              setIsLoading(false);
            }
          },
          // Remarque : il est important de traiter les erreurs ici
          // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
          // des exceptions provenant de réels bugs du composant.
          (error) => {
            setIsLoading(false);
          }
        )
    }, 1500);
    return () => clearTimeout(timer);
  });


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handleBarcodeChange = (e) => {
    setBarcode(e.target.value);
  }

  const handleClubChange = (e) => {
    setClub(e.target.value);
  }

  return (
    <section className="home-section is-relative is-clipped">
      <div className={`pageloader ${isLoading ? "is-active" : ""}`}></div>
      <div className="is-hidden-touch has-background-dark" style={{"position": "absolute", "top": 0, "left": 0, "width": "70%", "height": "100%"}}></div>
      <div className="is-hidden-desktop has-background-dark is-fullwidth" style={{"position": "absolute", "top": 0, "left": 0, "width": "100%", "height": "100%"}}></div>
      <div className="container mx-auto is-relative">
            <div>
              <div className="is-vcentered columns is-multiline">
                <div className="column is-6 is-5-desktop mb-5">
                  <div>
                    <img src={logo} alt="Maxiclub" />
                    <h2 className="has-text-white mb-4 is-size-1 is-size-3-mobile has-text-weight-bold">Bienvenue dans votre espace membre Maxiclub!</h2>
                  </div>
                </div>
                <div className="column is-6 is-4-desktop mx-auto">
                  <div className="box">
                      <h3 className="mb-5 is-size-4 has-text-weight-bold">Connexion</h3>
                      <hr/>
                      <div className="field">
                        <label className="label">Votre courriel</label>
                        <div className="control">
                          <input className="input" type="text" placeholder="votre@courriel.com" value={email} onChange={handleEmailChange} />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Votre numéro de membre</label>
                        <div className="control">
                          <input className="input" type="text" placeholder="ex: A84C264..." value={barcode} onChange={handleBarcodeChange} />
                        </div>
                        <Link to="/numero-de-membre"><small className="has-text-dark"><u>Retrouver mon num&eacute;ro de membre</u></small></Link>
                      </div>
                      <div className="field">
                        <label className="label">Votre club</label>
                        <p className="control is-fullwidth">
                          <span className="select is-fullwidth">
                            <select value={club} onChange={handleClubChange}>
                              <option value="4893">Belvédère</option>
                              <option value="0610">Rock-Forest</option>
                            </select>
                          </span>
                        </p>
                      </div>
                      <br/>
                      <button className={`button is-primary py-2 is-fullwidth ${isLoginLoading ? "is-loading" : ""}`} onClick={handleLogin}>Se connecter</button>
                      {
                        error != '' ? (
                          <p className="has-text-danger">
                            <br/>
                            <b>{error}</b>
                          </p>
                        ) : null
                      }
                      <hr/>
                      <p>Vous n'&ecirc;tes pas encore membre?</p>
                      <a className="is-text is-underline" href="https://www.maxiclub.com/abonnement/">Inscrivez-vous</a>
                  </div>
                </div>
              </div>
              <p><a href="https://maxiclub.com" className="has-text-white"><small><u>Retour&nbsp;au&nbsp;site&nbsp;principal</u></small></a> <a href="https://help.abcfinancial.com/help/mico_help/site_footer/en-terms_and_conditions.html" style={{"marginLeft": "10px"}} className="has-text-white"><small><u>Termes&nbsp;&&nbsp;Conditions</u></small></a> <a href="https://abcfitness.com/privacy-policy/" className="has-text-white" style={{"marginLeft": "10px"}}><small><u>Politique&nbsp;de&nbsp;confidentialit&eacute;</u></small></a></p>
              <br/>
              <p className="has-text-white"><small>© 2021 Maxi-Club - Tous droits réservés</small></p>
            </div>
      </div>
    </section>
  );
}

export default Login;
