import { useSelector } from 'react-redux'
import { beautifyDate } from '../../utils/functions'

function PersonalInfo() {

  const defaultGym = useSelector(state => state.defaultGym);
  const user = useSelector(state => state.user);

  return (
    <div>
      <h1 className="title">Mes infos personnelles</h1>
      <div className="box">
        <h2 className="title is-size-4">{user.personal.firstName} {user.personal.lastName}</h2>
        <p><b>Adresse:</b> {user.personal.addressLine1} {user.personal.city} {user.personal.state} {user.personal.postalCode}</p>
        <hr/>
        <p><b>Courriel:</b> {user.personal.email}</p>
        <p><b>T&eacute;l&eacute;phone:</b> {user.personal.primaryPhone}</p>
        <p><b>Contact d'urgence:</b> {user.personal.emergencyContactName}</p>
        <p><b>Num&eacute;ro d'urgence:</b> {user.personal.emergencyPhone}</p>
      </div>
    </div>
  );
}

export default PersonalInfo;
