import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import store from './utils/store'
import { Provider } from 'react-redux'

import Login from './pages/Login'
import Signup from './pages/Signup'
import AgreementNumber from './pages/LostAgreement'
import Dashboard from './pages/dashboard'
import NotFound from './pages/NotFound';

function PrivateRoute({ children, ...rest }) {
  
  const user = useSelector(state => state.user)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ children, ...rest }) {
  
  const user = useSelector(state => state.user)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/admin",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function App() {
  return (
    <Provider store={store}>
      <Router>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route path="/inscription">
              <Signup />
            </Route>
            <Route path="/numero-de-membre">
              <AgreementNumber />
            </Route>
            <PrivateRoute path="/admin">
              <Dashboard />
            </PrivateRoute>
            <Route>
              <NotFound />
            </Route>
          </Switch>
      </Router>
    </Provider>
  );
}

export default App;
