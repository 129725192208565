import logo from '../imgs/logo.svg';
import { Link } from "react-router-dom";

function Signup() {
  return (
    <section className="home-section section is-relative is-clipped">
      <div className="is-hidden-touch has-background-dark" style={{"position": "absolute", "top": 0, "left": 0, "width": "70%", "height": "100%"}}></div>
      <div className="is-hidden-desktop has-background-dark is-fullwidth" style={{"position": "absolute", "top": 0, "left": 0, "width": "100%", "height": "100%"}}></div>
      <div className="container mx-auto is-relative">
        <div className="is-vcentered columns is-multiline">
          <div className="column is-6 is-5-desktop mb-5">
            <div>
              <img src={logo} alt="Maxiclub" />
              <h2 className="has-text-white mb-4 is-size-1 is-size-3-mobile has-text-weight-bold">Bienvenue dans votre espace membre Maxiclub!</h2>
            </div>
          </div>
          <div className="column is-6 is-4-desktop mx-auto">
            <div className="box">
              <div>
                <h3 className="mb-5 is-size-4 has-text-weight-bold">Inscription</h3>
                <hr/>
                <p>
                  Vous serez redirigez vers le portail de connexion de MyIClubOnline, notre partenaire logiciel.
                </p>
                <br/>
                <a className="button is-primary py-2 is-fullwidth" href="https://oua.abcfinancial.com:443/uaa/oauth/register_client?clientId=maxiClubsApp&client_secret=4f7a11f8bcb9406ba914ba82ac9f1a4f">S'inscrire</a>
                { /* <a className="button is-primary py-2 is-fullwidth" href=": https://qa-oua.abcfinancial.com:443/uaa/oauth/register_client?clientId=maxiClubsApp&client_secret=4f7a11f8bcb9406ba914ba82ac9f1a4f">S'inscrire</a> */ }
              </div>
              <hr/>
              <p>Vous avez d&eacute;j&agrave; un compte?</p>
              <Link to="/">Connectez-vous</Link>
            </div>
          </div>
        </div>
        <p><a href="https://help.abcfinancial.com/help/mico_help/site_footer/en-terms_and_conditions.html" className="has-text-white"><small><u>Termes & Conditions</u></small></a><a href="https://abcfitness.com/privacy-policy/" className="has-text-white" style={{"marginLeft": "10px"}}><small><u>Politique de confidentialit&eacute;</u></small></a></p>
        <p className="has-text-white"><small>© 2021 Maxi-Club - Tous droits réservés</small></p>
      </div>
    </section>
  );
}

export default Signup;
