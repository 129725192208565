import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { formatTime, beautifyDate } from '../../utils/functions'
import { setDefaultGym } from '../../utils/store'
import Modal from '../../components/Modal'
import { Link } from "react-router-dom";

function Home() {

  const [classes, setClasses] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [today, setToday] = React.useState(new Date());
  const [isLoadingModal, setIsLoadingModal] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [modalClass, setModalClass] = React.useState({});
  const [modalSuccessMessage, setModalSuccessMessage] = React.useState('');
  const [modalErrorMessage, setModalErrorMessage] = React.useState('');

  const user = useSelector(state => state.user);
  const apiUrl = useSelector(state => state.apiUrl);
  const defaultGym = useSelector(state => state.defaultGym);
  const dispatch = useDispatch();

  const subscribeUser = () => {
    
    setIsLoadingModal(true);

    let options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        club: `${defaultGym.id}`,
        memberId: `${user.memberId}`,
        eventId: `${modalClass.eventId}`,
      }),
    }

    fetch(apiUrl + '/cours/inscription.php', options)
    .then(res => res.json())
    .then(
      (result) => {
        setIsLoadingModal(false);
        if (result.success == true){
          setModalSuccessMessage('Votre inscription au cours a bien été enregistré.');
          setTimeout(function(){ 
            setShowModal(false);
            updateClasses();
          }, 3000);
        }else{
          setModalErrorMessage('Une erreur est survenue, veuillez réessayer plus tard.');
        }
      },
      // Remarque : il est important de traiter les erreurs ici
      // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
      // des exceptions provenant de réels bugs du composant.
      (error) => {
        setIsLoadingModal(false);
        setModalErrorMessage('Une erreur est survenue, veuillez réessayer plus tard.');
      }
    )
  }

  const openModalClass = (eventId) => {

    setIsLoadingModal(true);
    setModalSuccessMessage('');
    setModalErrorMessage('');
    setShowModal(true);

    let options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        club: `${defaultGym.id}`,
        eventId: `${eventId}`
      }),
    }

    fetch(apiUrl + '/cours/cours.php', options)
    .then(res => res.json())
    .then(
      (result) => {
        setModalClass(result);
        setIsLoadingModal(false);
      },
      // Remarque : il est important de traiter les erreurs ici
      // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
      // des exceptions provenant de réels bugs du composant.
      (error) => {
        console.log(error)
      }
    )
  }

  const updateClasses = () => {

    setIsLoading(true);
    
    let options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        club: `${defaultGym.id}`
      }),
    }

    fetch(apiUrl + '/cours/today.php', options)
    .then(res => res.json())
    .then(
      (results) => {
        setClasses(results);
        setIsLoading(false);
      },
      // Remarque : il est important de traiter les erreurs ici
      // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
      // des exceptions provenant de réels bugs du composant.
      (error) => {
        console.log(error)
      }
    )
  }

  function SkeletonRow(){
    return (
      <div className="panel" style={{height: '4em'}}>
        <div className="skeleton-box"></div>
      </div>
    );
  }

  function SkeletonRowsLoading(){
    
    var skeleton_rows = [];
    for (var i = 0; i < 3; i++) {
      skeleton_rows.push(
        <SkeletonRow key={i} />
      );
    }

    return(
      <div>
        <hr/>
        {skeleton_rows}
        <hr/>
      </div>
    );
  }

  function ClassRow(props) {
    let start_time = new Date(props.event.eventTimestamp.replace(" ", "T"));
    var end_time = new Date(start_time.getTime() + props.event.duration*60000);
    let nb_enrolled = 0;
    let enrolled = false;
    if (props.event.members != undefined){
      nb_enrolled = props.event.members.length;


      for (let i = 0; i < nb_enrolled; i++){
        let member = props.event.members[i];

        if (user.memberId == member.memberId){
          enrolled = true;
          break;
        }
      }
    }
    let available = props.event.maxAttendees-nb_enrolled;
    let stop_enroll = new Date(props.event.stopBookingTime.replace(" ", "T"));
    let enrollable = true;
    if (today > stop_enroll){
      enrollable = false;
    }
    if (available == 0){
      enrollable = false;
    }
    
    return (
      <div className="panel-block">
        <div className="columns is-mobile is-multiline">
          <input type="hidden" value={props.event.eventTimestamp} />
          <div className="column is-2-desktop is-6-mobile">{formatTime(start_time)} - {formatTime(end_time)}</div>
          <div className="column is-5-desktop is-6-mobile">{props.event.eventName}<br/><i>Prof : {props.event.employeeName}</i></div>
          <div className="column is-3-desktop is-6-mobile"><b>{available}</b> place(s) dispo</div>
          <div className="column is-2-desktop is-6-mobile">
            {
              enrolled ? (
                <small><i>&#10003; Inscrit</i></small>
              ) : enrollable ? (
                <button className="button is-small is-primary" onClick={() => { openModalClass(props.event.eventId) }}>S'inscrire</button>
              ) : null
            }
          </div>
        </div>
      </div>
    );
  }

  const handleClubChange = (e) => {
    dispatch(setDefaultGym(e.target.value))
  }

  var rows = [];
  for (var i = 0; i < classes.length; i++) {
    rows.push(<ClassRow key={i} event={classes[i]} />);
  }

  let modal_start_time = new Date(modalClass.eventTimestamp);
  var modal_end_time = new Date(modal_start_time.getTime() + modalClass.duration*60000);

  React.useEffect(() => {
    updateClasses();
  }, [defaultGym]);

  return (
    <div>
      <h1 className="title">Bienvenue, {user.personal.firstName}!</h1>
      <div className="box">
          <div className="is-hidden-tablet">
            <label className="label">
              Mon club
            </label>
            <p className="control is-fullwidth">
                <span className="select is-fullwidth">
                    <select value={defaultGym.id} onChange={handleClubChange}>
                        <option value="4893">Belvédère</option>
                        <option value="0610">Rock-Forest</option>
                    </select>
                </span>
            </p>
            <hr/>
          </div>
          <h2 className="title is-size-5"><u>Cours de la journée</u></h2>
          {
            isLoading ? (
              <SkeletonRowsLoading />
            ) : rows.length > 0 ? (
              <>
                <hr style={{marginBottom: 0}} />
                <nav className="class-day-panel">
                  {rows}
                </nav>
                <hr style={{marginTop: 0}} />
              </> 
            ) : (
              <div>
                <hr/>
                <i>Aucun &eacute;v&eacute;nement à l'agenda aujourd'hui.</i>
                <hr/>
              </div>
            )
          }
          <Link className="button is-primary" to={`/admin/cours-de-groupe`}>Consulter la grille horaire</Link>
      </div>
      <div className="columns">
        <div className="column">
          <div className="box" style={{minHeight: '400px'}}>
              <h2 className="title is-size-5"><u>Mon club principal</u></h2>
              <img src={defaultGym.img} style={{borderRadius: '6px'}} />
              <p><b>{defaultGym.name}</b></p>
              <p>{defaultGym.address}</p>
              <p><a href={'https://maps.google.com/?q='+defaultGym.address} target="_blank"><u>Obtenir l'itin&eacute;raire</u></a></p>
          </div>
        </div>
        <div className="column">
          <div className="box" style={{minHeight: '400px'}}>
              <h2 className="title is-size-5"><u>Mon profil</u></h2>
              <p>{user.personal.firstName} {user.personal.lastName}</p>
              <p>{user.personal.addressLine1} {user.personal.city} {user.personal.state} {user.personal.postalCode}</p>
              <p>{user.personal.email}</p>
              <p>{user.personal.primaryPhone}</p>
              <hr/>
              <h2 className="title is-size-5"><u>Mon forfait</u></h2>
              <p>{user.agreement.paymentPlan}</p>
              <p><u>Expiration</u>: {beautifyDate(new Date(user.agreement.expirationDate))}</p>
          </div>
        </div>
      </div>
      <Modal onClose={() => {
        setShowModal(false)
      }} 
      show={showModal}>
        <div className="has-text-centered">
          <h2>Inscription au cours</h2>
          <br/>
          {
            isLoadingModal ? (
              <div>
                <div className="skeleton-box" style={{height: '1em'}}></div>
                <div className="skeleton-box" style={{height: '1em'}}></div>
                <div className="skeleton-box" style={{height: '1em'}}></div>
                <br/><br/><br/>
              </div>
            ) : modalSuccessMessage != '' ? (
              <div>
                <p className="has-text-success">
                  {modalSuccessMessage}
                </p>
                
                <br/>
                <div>
                  <button className="button" onClick={() => {
                    setShowModal(false)
                  }}>
                    Fermer la fen&ecirc;tre
                  </button>
                </div>
              </div>
            ) : modalErrorMessage != '' ? (
              <div>
                <p className="has-text-danger">
                  {modalErrorMessage}
                </p>
                
                <br/>
                <div>
                  <button className="button" onClick={() => {
                    setShowModal(false)
                  }}>
                    Fermer la fen&ecirc;tre
                  </button>
                </div>
              </div>
            ) : (
              <>
                <p>
                  Souhaitez vous vraiment vous inscrire au cours {modalClass.eventName} le {beautifyDate(new Date(modalClass.eventTimestamp))} de {formatTime(modal_start_time)} &agrave; {formatTime(modal_end_time)}
                </p>
                
                <br/>
                <div>
                  <button className="button" onClick={() => {
                    setShowModal(false)
                  }}>
                    Fermer la fen&ecirc;tre
                  </button>

                  <button style={{marginLeft: '10px'}} className="button is-primary" onClick={subscribeUser}>
                    S'inscrire
                  </button>
                </div>
              </>
            )
          }
        </div>
      </Modal>
    </div>
  );
}

export default Home;
