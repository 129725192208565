import React, { useEffect } from 'react'
import Modal from '../../components/Modal'
import { useSelector } from 'react-redux'
import { formatDate, formatTime, beautifyDate, beautifyDateWithoutYear, getMonday } from '../../utils/functions'

function MyClasses() {
  
  const [classes, setClasses] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [today, setToday] = React.useState(new Date());
  const [focusDate, setFocusDate] = React.useState(new Date());
  const [monday, setMonday] = React.useState(getMonday(focusDate));
  const [isLoadingModal, setIsLoadingModal] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [modalClass, setModalClass] = React.useState({});
  const [modalSuccessMessage, setModalSuccessMessage] = React.useState('');
  const [modalErrorMessage, setModalErrorMessage] = React.useState('');

  const apiUrl = useSelector(state => state.apiUrl);
  const defaultGym = useSelector(state => state.defaultGym);
  const user = useSelector(state => state.user);

  const unSubscribeUser = () => {
    
    setIsLoadingModal(true);

    let options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        club: `${defaultGym.id}`,
        memberId: `${user.memberId}`,
        eventId: `${modalClass.eventId}`,
      }),
    }

    fetch(apiUrl + '/cours/desinscription.php', options)
    .then(res => res.json())
    .then(
      (result) => {
        setIsLoadingModal(false);
        if (result.success == true){
          setModalSuccessMessage('Votre inscription au cours a été annulé.');
          setTimeout(function(){ 
            setShowModal(false);
            updateClasses();
          }, 3000);
        }else{
          setModalErrorMessage('Une erreur est survenue, veuillez réessayer plus tard.');
        }
      },
      // Remarque : il est important de traiter les erreurs ici
      // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
      // des exceptions provenant de réels bugs du composant.
      (error) => {
        setIsLoadingModal(false);
        setModalErrorMessage('Une erreur est survenue, veuillez réessayer plus tard.');
      }
    )
  }

  const openModalClass = (eventId) => {

    setIsLoadingModal(true);
    setModalSuccessMessage('');
    setModalErrorMessage('');
    setShowModal(true);

    let options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        club: `${defaultGym.id}`,
        eventId: `${eventId}`
      }),
    }

    fetch(apiUrl + '/cours/cours.php', options)
    .then(res => res.json())
    .then(
      (result) => {
        setModalClass(result);
        setIsLoadingModal(false);
      },
      // Remarque : il est important de traiter les erreurs ici
      // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
      // des exceptions provenant de réels bugs du composant.
      (error) => {
        console.log(error)
      }
    )
  }

  const updateClasses = () => {

    setIsLoading(true);
    let format_monday = formatDate(monday);
    
    let options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        club: `${defaultGym.id}`,
        monday: `${format_monday}`,
        memberId: `${user.memberId}`
      }),
    }

    fetch(apiUrl + '/cours/mes-cours.php', options)
    .then(res => res.json())
    .then(
      (results) => {
        let dates = {};
        for (let i = 0; i < results.length; i++){
          let event = results[i];
          let event_date = event.eventTimestamp.split(" ")[0];
          if (!(event_date in dates)){
            dates[event_date] = [];
          }
          dates[event_date].push(event);
        }
        setClasses(dates);
        setIsLoading(false);
      },
      // Remarque : il est important de traiter les erreurs ici
      // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
      // des exceptions provenant de réels bugs du composant.
      (error) => {
        console.log(error)
      }
    )
  }

  const rewindWeek = () => {
    let newDate = new Date(focusDate);
    newDate.setDate(newDate.getDate() - 7);
    setFocusDate(newDate);
    setMonday(getMonday(newDate));
  }

  const goToToday = () => {
    let newDate = new Date();
    setFocusDate(newDate);
    setMonday(getMonday(newDate));
  }

  const fastForwardWeek = () => {
    let newDate = new Date(focusDate);
    newDate.setDate(newDate.getDate() + 7);
    setFocusDate(newDate);
    setMonday(getMonday(newDate));
  }

  useEffect(() => {
    updateClasses();
  }, [focusDate, defaultGym]);

  function SkeletonRow(){
    return (
      <div className="panel" style={{height: '4em'}}>
        <div className="skeleton-box"></div>
      </div>
    );
  }

  function SkeletonRowsLoading(){
    
    var skeleton_rows = [];
    for (var i = 0; i < 5; i++) {
      skeleton_rows.push(
        <SkeletonRow key={i} />
      );
    }

    return(
      <div>
        {skeleton_rows}
      </div>
    );
  }

  function ClassRow(props) {
    let start_time = new Date(props.event.eventTimestamp.replace(" ", "T"));
    var end_time = new Date(start_time.getTime() + props.event.duration*60000);
    let nb_enrolled = 0;
    let enrolled = false;
    if (props.event.members != undefined){
      nb_enrolled = props.event.members.length;


      for (let i = 0; i < nb_enrolled; i++){
        let member = props.event.members[i];

        if (user.memberId == member.memberId){
          enrolled = true;
          break;
        }
      }
    }
    let stop_cancel = new Date(props.event.allowCancelBefore.replace(" ", "T"));
    let cancelable = true;
    if (today > stop_cancel){
      cancelable = false;
    }
    
    return (
      <div className="panel-block">
        <div className="columns is-mobile is-multiline">
          <input type="hidden" value={props.event.eventTimestamp} />
          <div className="column is-2-desktop is-6-mobile">{formatTime(start_time)} - {formatTime(end_time)}</div>
          <div className="column is-5-desktop is-6-mobile">{props.event.eventName}</div>
          <div className="column is-3-desktop is-6-mobile">{props.event.locationName}</div>
          <div className="column is-2-desktop is-6-mobile">
            {
              cancelable ? (
                <button className="button is-small is-danger" onClick={() => { openModalClass(props.event.eventId) }}>Se d&eacute;sinscrire</button>
              ) : (
                <small><i>&#10003; Inscrit</i></small>
              )
            }
          </div>
        </div>
      </div>
    );
  }

  function DateClassesRow(props) {
    var classes_rows = [];
    for (var i = 0; i < props.events.length; i++) {
      classes_rows.push(<ClassRow key={i} event={props.events[i]} />);
    }
    return (
      <nav className="panel class-day-panel">
        <p className="panel-heading">
          {beautifyDate(new Date(props.date + " 12:00:00"))}
        </p>
        {
          classes_rows.length > 0 ? (
            <>
              {classes_rows}
            </>
          ) : (
            <p>
              Aucun &eacute;v&eacute;nement &agrave; cette date.
            </p>
          )
        }
        
      </nav>
    );
  }
  
  var rows = [];
  for (const property in classes) {
    rows.push(<DateClassesRow key={property} date={property} events={classes[property]} />);
  }
  let end_of_week = new Date(monday);
  end_of_week.setDate(monday.getDate() + 6);

  let modal_start_time = new Date(modalClass.eventTimestamp);
  var modal_end_time = new Date(modal_start_time.getTime() + modalClass.duration*60000);
  return (
    <div>
      <h1 className="title">Mes activités</h1>
      <div className="box">
          <div className="columns is-multiline">
            <div className="column is-5-desktop is-12-tablet">
              <button className="button is-primary" onClick={rewindWeek}><i className="arrow arrow-left"></i></button>
              <button className="button is-primary" style={{"marginLeft": "10px"}} onClick={goToToday}>Aujourd'hui</button>
              <button className="button is-primary" style={{"marginLeft": "10px"}} onClick={fastForwardWeek}><i className="arrow arrow-right"></i></button>
            </div>
            <div className="column is-12-touch" style={{display: 'flex', alignItems: 'center'}}>
              <span>{beautifyDateWithoutYear(monday)} - {beautifyDate(end_of_week)}</span>
            </div>
          </div>
          <hr/>
          {
            isLoading ? (
              <SkeletonRowsLoading />
            ) : rows.length > 0 ? (
              <>
                {rows}
              </> 
            ) : (
            <p className="has-text-centered">
              <i>Vous n'&ecirc;tes inscrits &agrave; aucun cours cette semaine.</i>
            </p>
            )
          }
      </div>
      <Modal onClose={() => {
        setShowModal(false)
      }} 
      show={showModal}>
        <div className="has-text-centered">
          <h2>Désincription au cours</h2>
          <br/>
          {
            isLoadingModal ? (
              <div>
                <div className="skeleton-box" style={{height: '1em'}}></div>
                <div className="skeleton-box" style={{height: '1em'}}></div>
                <div className="skeleton-box" style={{height: '1em'}}></div>
                <br/><br/><br/>
              </div>
            ) : modalSuccessMessage != '' ? (
              <div>
                <p className="has-text-success">
                  {modalSuccessMessage}
                </p>
                
                <br/>
                <div>
                  <button className="button" onClick={() => {
                    setShowModal(false)
                  }}>
                    Fermer la fen&ecirc;tre
                  </button>
                </div>
              </div>
            ) : modalErrorMessage != '' ? (
              <div>
                <p className="has-text-danger">
                  {modalErrorMessage}
                </p>
                
                <br/>
                <div>
                  <button className="button" onClick={() => {
                    setShowModal(false)
                  }}>
                    Fermer la fen&ecirc;tre
                  </button>
                </div>
              </div>
            ) : (
              <>
                <p>
                  Souhaitez vous vraiment vous d&eacute;sinscrire au cours {modalClass.eventName} le {beautifyDate(new Date(modalClass.eventTimestamp))} de {formatTime(modal_start_time)} &agrave; {formatTime(modal_end_time)}
                </p>
                
                <br/>
                <div>
                  <button className="button" onClick={() => {
                    setShowModal(false)
                  }}>
                    Fermer la fen&ecirc;tre
                  </button>
                  <button style={{marginLeft: '10px'}} className="button is-danger" onClick={unSubscribeUser}>
                    Se d&eacute;sinscrire
                  </button>
                </div>
              </>
            )
          }
        </div>
      </Modal>
    </div>
  );
}

export default MyClasses;
