import logo from '../imgs/logo.svg';
import { Link } from "react-router-dom";

function Signup() {
  return (
    <section className="home-section section is-relative is-clipped">
      <div className="is-hidden-touch has-background-dark" style={{"position": "absolute", "top": 0, "left": 0, "width": "70%", "height": "100%"}}></div>
      <div className="is-hidden-desktop has-background-dark is-fullwidth" style={{"position": "absolute", "top": 0, "left": 0, "width": "100%", "height": "100%"}}></div>
      <div className="container mx-auto is-relative">
        <div className="is-vcentered columns is-multiline">
          <div className="column is-6 is-5-desktop mb-5">
            <div>
              <img src={logo} alt="Maxiclub" />
              <h2 className="has-text-white mb-4 is-size-1 is-size-3-mobile has-text-weight-bold">Bienvenue dans votre espace membre Maxiclub!</h2>
            </div>
          </div>
          <div className="column is-6 is-4-desktop mx-auto">
            <div className="box">
              <form action="#">
                <h3 className="mb-5 is-size-4 has-text-weight-bold">Retrouver mon num&eacute;ro de membre</h3>

                <p>
                    Votre numéro de membre est le numéro qui vous est attribué lors de votre inscription au club.
                    <br/><br/>
                    Il se trouve sous le code-barres, sur votre carte de membre qui vous est remis lors de votre première visite.
                    <br/><br/>
                    Si vous ne retrouvez pas, contactez-nous au club pour que vous nous assistions.
                </p>
                {/* <hr/>
                <small>Si vous ne retrouvez pas votre numéro de membre, entrez votre courriel ci-dessous, nous vous enverrons votre num&eacute;ro par courriel.</small>
                <br/><br/>
                <div className="field">
                  <div className="control">
                    <input className="input" type="text" placeholder="Votre courriel" />
                  </div>
                </div>
                <button className="button is-primary">Envoyer</button>
                */}
                <hr/> 
                <p>Vous n'&ecirc;tes pas encore membre?</p>
                <a className="is-text is-underline" href="https://www.maxiclub.com/abonnement/">Inscrivez-vous ici</a>
                <hr/>
                <Link to="/">⬅️&nbsp;&nbsp;Retour à la connexion</Link>
              </form>
            </div>
          </div>
        </div>
        <p><a href="https://help.abcfinancial.com/help/mico_help/site_footer/en-terms_and_conditions.html" className="has-text-white"><small><u>Termes & Conditions</u></small></a><a href="https://abcfitness.com/privacy-policy/" className="has-text-white" style={{"marginLeft": "10px"}}><small><u>Politique de confidentialit&eacute;</u></small></a></p>
        <p className="has-text-white"><small>© 2021 Maxi-Club - Tous droits réservés</small></p>
      </div>
    </section>
  );
}

export default Signup;
