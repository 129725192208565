import React from 'react';
import { useSelector } from 'react-redux'
import logo from '../imgs/logo.svg';
import { Link } from "react-router-dom";

function NotFound() {
  const user = useSelector(state => state.user);

  return (
    <section className="home-section is-relative is-clipped">
      <div className="is-hidden-touch has-background-dark" style={{"position": "absolute", "top": 0, "left": 0, "width": "70%", "height": "100%"}}></div>
      <div className="is-hidden-desktop has-background-dark is-fullwidth" style={{"position": "absolute", "top": 0, "left": 0, "width": "100%", "height": "100%"}}></div>
      <div className="container mx-auto is-relative">
            <div>
              <div className="is-vcentered is-multiline">
                <div>
                  <div>
                    <img src={logo} alt="Maxiclub" />
                    <h2 className="has-text-white mb-4 is-size-1 is-size-3-mobile has-text-weight-bold">Erreur 404</h2>
                    <h2 className="has-text-white mb-4 is-size-3 is-size-3-mobile has-text-weight-bold">La page n'a pas été trouvée!</h2>
                    {
                      user ? (
                        <Link className="button is-primary" to="/admin">Retour à l'accueil</Link>
                      ) : (
                        <Link className="button is-primary" to="/">Retour à l'accueil</Link>
                      )
                    }
                  </div>
                </div>
              </div>
              <br/>
              <p><a href="https://maxiclub.com" className="has-text-white"><small><u>Retour&nbsp;au&nbsp;site&nbsp;principal</u></small></a> <a href="https://help.abcfinancial.com/help/mico_help/site_footer/en-terms_and_conditions.html" style={{"marginLeft": "10px"}} className="has-text-white"><small><u>Termes&nbsp;&&nbsp;Conditions</u></small></a> <a href="https://abcfitness.com/privacy-policy/" className="has-text-white" style={{"marginLeft": "10px"}}><small><u>Politique&nbsp;de&nbsp;confidentialit&eacute;</u></small></a></p>
              <br/>
              <p className="has-text-white"><small>© 2021 Maxi-Club - Tous droits réservés</small></p>
            </div>
      </div>
    </section>
  );
}

export default NotFound;
