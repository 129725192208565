

export const getMonday = (d) => {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

export const isToday = (date) => {
    const today = new Date();

    return date.getDate() == today.getDate() &&
            date.getMonth() == today.getMonth() &&
            date.getFullYear() == today.getFullYear();
}

export const beautifyDate = (date) => {
    
    if (isToday(date)) {
        return 'Aujourd\'hui';
    }

    let day = date.getDay();
    let day_label;
    switch(day) {
      case 0:
        day_label = 'Dimanche';
        break;
      case 1:
        day_label = 'Lundi';
        break;
      case 2:
        day_label = 'Mardi';
        break;
      case 3:
        day_label = 'Mercredi';
        break;
      case 4:
        day_label = 'Jeudi';
        break;
      case 5:
        day_label = 'Vendredi';
        break;
      case 6:
        day_label = 'Samedi';
        break;
      default:
        // code block
    }

    let month = date.getMonth() +1;
    let month_label;
    switch(month) {
      case 1:
        month_label = 'janvier';
        break;
      case 2:
        month_label = 'février';
        break;
      case 3:
        month_label = 'mars';
        break;
      case 4:
        month_label = 'avril';
        break;
      case 5:
        month_label = 'mai';
        break;
      case 6:
        month_label = 'juin';
        break;
      case 7:
        month_label = 'juillet';
        break;
      case 8:
        month_label = 'août';
        break;
      case 9:
        month_label = 'septembre';
        break;
      case 10:
        month_label = 'octobre';
        break;
      case 11:
        month_label = 'novembre';
        break;
      case 12:
        month_label = 'décembre';
        break;
      default:
        // code block
    }

    return `${day_label} ${date.getDate()} ${month_label} ${date.getFullYear()}`;
};

export const beautifyDateWithoutYear = (date) => {
  
  if (isToday(date)) {
      return 'Aujourd\'hui';
  }

  let day = date.getDay();
  let day_label;
  switch(day) {
    case 0:
      day_label = 'Dimanche';
      break;
    case 1:
      day_label = 'Lundi';
      break;
    case 2:
      day_label = 'Mardi';
      break;
    case 3:
      day_label = 'Mercredi';
      break;
    case 4:
      day_label = 'Jeudi';
      break;
    case 5:
      day_label = 'Vendredi';
      break;
    case 6:
      day_label = 'Samedi';
      break;
    default:
      // code block
  }

  let month = date.getMonth() +1;
  let month_label;
  switch(month) {
    case 1:
      month_label = 'janvier';
      break;
    case 2:
      month_label = 'février';
      break;
    case 3:
      month_label = 'mars';
      break;
    case 4:
      month_label = 'avril';
      break;
    case 5:
      month_label = 'mai';
      break;
    case 6:
      month_label = 'juin';
      break;
    case 7:
      month_label = 'juillet';
      break;
    case 8:
      month_label = 'août';
      break;
    case 9:
      month_label = 'septembre';
      break;
    case 10:
      month_label = 'octobre';
      break;
    case 11:
      month_label = 'novembre';
      break;
    case 12:
      month_label = 'décembre';
      break;
    default:
      // code block
  }

  return `${day_label} ${date.getDate()} ${month_label}`;
};

export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const formatTime = (date) => {
    let minutes = date.getMinutes();
    if (minutes == 0){
      minutes = '00';
    }
    return date.getHours() + ':' + minutes;
}