import { useSelector } from 'react-redux'
import { beautifyDate } from '../../utils/functions'

function Contract() {

  const defaultGym = useSelector(state => state.defaultGym);
  const user = useSelector(state => state.user);

  return (
    <div>
      <h1 className="title">Mon contrat</h1>
      <div className="box">
        <h2 className="title is-size-4">Contrat #{user.agreement.agreementNumber}</h2>
        <p><b>D&eacute;but du contrat:</b> {beautifyDate(new Date(user.agreement.beginDate))}</p>
        <p><b>Expiration du contrat:</b> {beautifyDate(new Date(user.agreement.expirationDate))}</p>
        <hr/>
        <p><b>Abonnement:</b> {user.agreement.paymentPlan}</p>
        <p><b>R&eacute;currence des paiements:</b> {user.agreement.paymentFrequency}</p>
        <p><b>Type d'adh&eacute;sion:</b> {user.agreement.membershipType}</p>
        <p><b>Renouvellement automatique:</b> {user.agreement.renewalType == 'None' ? 'Non' : 'Oui'}</p>
      </div>
    </div>
  );
}

export default Contract;
