import { createSlice, configureStore } from '@reduxjs/toolkit'

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('store', serializedState);
  } catch {
    // ignore write errors
  }
};

const loadState = () => {
  try {
    const state = localStorage.getItem('store');
    if (state === null) {
      return undefined;
    }
    return JSON.parse(state);
  } catch (err) {
    return undefined;
  }
};


const deleteSavedState = () => {
  try {
    localStorage.removeItem('store');
  } catch (err) {
    //
  }
};

const gyms = {
  '4893': {
      id: '4893',
      name: 'Maxi Club Belvedere',
      address: '350 rue Belvedere Sud, Sherbrooke, QC, J1H 4B5',
      img: '/imgs/maxiclub-belvedere.jpg'
  },
  '0610': {
      id: '0610',
      name: 'Maxi Club Rock Forest',
      address: '4300 boulevard Bourque, Sherbrooke, QC, J1N 2A6',
      img: '/imgs/maxiclub-rockforest.jpg',
  }
};

const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    user: null,
    apiUrl: 'https://www.maxiclub.com/api',
    defaultGym: {
        id: '4893'
    }
  },
  reducers: {
    setDefaultGym: (state, action) => {
      let newGym = gyms[action.payload];
      state.defaultGym = newGym;
    },
    login: (state, action) => {
      state.user = action.payload
      saveState(state);
    },
    logout: state => {
      state.user = null
      deleteSavedState();
    }
  }
})

export const { setDefaultGym, login, logout } = counterSlice.actions

const store = configureStore({
  reducer: counterSlice.reducer,
  preloadedState: loadState()
});

export default store;