import logo from '../../imgs/logo.svg';
import { useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Link
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { setDefaultGym, logout } from '../../utils/store'

import Home from './Home';
import MyClasses from './MyClasses';
import Classes from './Classes';
import PersonalInfo from './PersonalInfo';
import Contract from './Contract';

function Dashboard() {

  let { path, url } = useRouteMatch();
  const [showMenu, setShowMenu] = useState(false);

  const dispatch = useDispatch();
  const defaultGym = useSelector(state => state.defaultGym);

  const handleClubChange = (e) => {
    dispatch(setDefaultGym(e.target.value))
  }

  return (
    <Router>
        <div className="has-navbar-fixed-top">
            <nav className="navbar has-background-dark">
                <div className="container">
                    <div className="navbar-brand">
                        <div className="navbar-item" style={{"paddingTop": 0, "paddingBottom": 0}}>
                            <img className="image" src={logo} alt="Maxiclub" /> 
                            <a href="https://maxiclub.com" className="has-text-white is-hidden-touch" style={{"marginLeft": "10px"}}><u>Retour au site principal</u></a>
                        </div>
                        <div className="navbar-item is-hidden-tablet" style={{"paddingTop": 0, "paddingBottom": 0, marginLeft: 'auto'}}>
                            <a role="button" className={`navbar-burger ${showMenu ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" onClick={() => setShowMenu(!showMenu)}>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
            <section className="dashboard is-relative is-clipped">
                <div className="container">
                    <div className="columns">
                        <div className={`admin-menu-container column is-3 ${showMenu ? "is-active" : ""}`}>
                            <aside className="menu">
                                <p className="menu-label">
                                    Mon club
                                </p>
                                <p className="control is-fullwidth">
                                    <span className="select is-fullwidth">
                                        <select value={defaultGym.id} onChange={handleClubChange}>
                                            <option value="4893">Belvédère</option>
                                            <option value="0610">Rock-Forest</option>
                                        </select>
                                    </span>
                                </p>
                                <hr/>
                                <ul className="menu-list">
                                    <li><Link onClick={() => setShowMenu(false)} to={`${url}`}>Accueil</Link></li>
                                </ul>
                                <p className="menu-label">
                                    Cours
                                </p>
                                <ul className="menu-list">
                                    <li><Link onClick={() => setShowMenu(false)} to={`${url}/mes-activites`}>Mes activit&eacute;s</Link></li>
                                    <li><Link onClick={() => setShowMenu(false)} to={`${url}/cours-de-groupe`}>Cours de groupe</Link></li>
                                </ul>
                                <p className="menu-label">
                                    Mon compte
                                </p>
                                <ul className="menu-list">
                                    <li><Link onClick={() => setShowMenu(false)} to={`${url}/mes-infos`}>Infos personnelles</Link></li>
                                    <li><Link onClick={() => setShowMenu(false)} to={`${url}/mon-contrat`}>Mon contrat</Link></li>
                                </ul>
                                <hr/>
                                <ul className="menu-list">
                                    <li><a className="has-background-danger has-text-white" onClick={() => {dispatch(logout())}}>Deconnexion</a></li>
                                </ul>
                            </aside>
                        </div>
                        <div className="column is-9">
                            <Switch>
                                <Route exact path={path}>
                                    <Home />
                                </Route>
                                <Route exact path={`${path}/mes-activites`}>
                                    <MyClasses />
                                </Route>
                                <Route exact path={`${path}/cours-de-groupe`}>
                                    <Classes />
                                </Route>
                                <Route exact path={`${path}/mes-infos`}>
                                    <PersonalInfo />
                                </Route>
                                <Route exact path={`${path}/mon-contrat`}>
                                    <Contract />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </section>
            <div className="has-text-centered">
                <p>Abonnez vous pour vous tenir &agrave; jour</p>
                <div className="social-logos">
                    <a href="https://www.facebook.com/gymmaxiclub" target="_blank" className="social-logo">
                        <img src="/imgs/facebook-logo.png" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCxYOaPjnbn64-silMXY-y-Q" target="_blank" className="social-logo">
                        <img src="/imgs/youtube-logo.png" />
                    </a>
                    <a href="https://www.instagram.com/maxiclubgym/" target="_blank" className="social-logo">
                        <img src="/imgs/instagram-logo.png" />
                    </a>
                </div>
                <p><small>© 2021 Maxi-Club - Tous droits réservés</small></p>
            </div>
        </div>
    </Router>
  );
}

export default Dashboard;
